var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12", lg: "6" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(" " + _vm._s(_vm.userVal.name) + " "),
                _c(
                  "div",
                  {
                    staticClass: "float-right",
                    staticStyle: { color: "#ff0000" },
                    on: { click: _vm.onRemoveUser }
                  },
                  [_c("CIcon", { attrs: { name: "cil-trash", size: "lg" } })],
                  1
                )
              ]),
              _c(
                "CCardBody",
                [
                  _c("CInput", {
                    attrs: { label: "Name", placeholder: "Enter users name" },
                    model: {
                      value: _vm.userVal.name,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "name", $$v)
                      },
                      expression: "userVal.name"
                    }
                  }),
                  _c("CInput", {
                    attrs: { label: "Email", placeholder: "Enter users email" },
                    model: {
                      value: _vm.userVal.email,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "email", $$v)
                      },
                      expression: "userVal.email"
                    }
                  }),
                  _c("CInput", {
                    attrs: {
                      label: "Phone",
                      prepend: "+1",
                      placeholder: "Enter users phone"
                    },
                    model: {
                      value: _vm.userVal.phone_number,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "phone_number", $$v)
                      },
                      expression: "userVal.phone_number"
                    }
                  }),
                  !_vm.isEditMode
                    ? _c("CInput", {
                        attrs: {
                          label: "Password",
                          placeholder: "Enter password",
                          type: "password"
                        },
                        model: {
                          value: _vm.userVal.password,
                          callback: function($$v) {
                            _vm.$set(_vm.userVal, "password", $$v)
                          },
                          expression: "userVal.password"
                        }
                      })
                    : _vm._e(),
                  _c("label", [_vm._v("Sites")]),
                  _vm.showSite
                    ? _c(
                        "CMultiSelect",
                        {
                          attrs: {
                            options: _vm.siteData,
                            multiple: true,
                            search: true,
                            selected: _vm.selectedSites,
                            selection: true,
                            optionsEmptyPlaceholder: "No sites available",
                            searchPlaceholder: "Please select or search",
                            selectionType: "tags"
                          },
                          on: { update: _vm.onSiteSelectionChange }
                        },
                        [_vm._v(" > ")]
                      )
                    : _vm._e(),
                  _c(
                    "CRow",
                    { staticStyle: { "margin-top": "15px" } },
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "8" } },
                        [
                          _c("CSelect", {
                            attrs: {
                              label: "Group",
                              placeholder: "Please select",
                              options: _vm.roles,
                              value: _vm.userVal.group
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(_vm.userVal, "group", $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { staticClass: "text-center", attrs: { sm: "4" } },
                        [
                          _c(
                            "CCol",
                            {
                              staticClass: "col-form-label",
                              attrs: { tag: "label", sm: "12" }
                            },
                            [_vm._v(" Is Active ")]
                          ),
                          _c("CSwitch", {
                            staticClass: "mr-1",
                            attrs: {
                              color: "success",
                              checked: _vm.userVal.status,
                              shape: "pill"
                            },
                            on: {
                              "update:checked": function($event) {
                                return _vm.$set(_vm.userVal, "status", $event)
                              }
                            },
                            model: {
                              value: _vm.userVal.sites,
                              callback: function($$v) {
                                _vm.$set(_vm.userVal, "sites", $$v)
                              },
                              expression: "userVal.sites"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardFooter",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { sm: "6" } },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "light" },
                              on: { click: _vm.goBack }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { staticClass: "text-right", attrs: { sm: "6" } },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onSaveEditClick }
                            },
                            [_vm._v("Save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }